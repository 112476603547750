<template>
  <div class="container-fluid">
    <Alert v-if="alert && alert.layer === 'detail'" :alert="alert" />
    <h2 class="my-3">{{ locationDetails.longName }}</h2>
    <p>
      <b>
        You may change your Arrival and Departure Dates and/or select another
        Spot below. Additional fees may apply for some changes. Calculated
        payment or refund details will be displayed for confirmation prior to
        completing the change.
      </b>
    </p>
    <ValidationObserver
      ref="editResObserver"
      tag="form"
      id="editResForm"
      @submit.prevent="onSubmit"
    >
      <div class="row mx-0">
        <div
          class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4 spotSearch mb-3"
        >
          <div class="header-bar mx-n2 pt-2 pl-2 mb-2">Find Your Campsite</div>
          <FormErrorAlert
            v-if="errors.length > 0"
            :errors="errors"
            :formRef="this.$refs.editResObserver"
          />
          <SpotSearch
            :spots="searchResults"
            :classifications="filteredClassifications"
            :locationName="locationDetails.longName"
            :locationId="locationDetails.id"
            :editView="true"
            :spotReservationId="+reservationId"
            v-if="loaded"
          />
        </div>
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-8">
          <div class="mb-3 locationInformation">
            <LocationInformation :location="locationDetails" :pois="pois" />
          </div>
          <div class="mb-3">
            <SpotMap
              v-if="locationDetails.latitude && searchResults"
              :searchResults="searchResults"
              :locationLatitude="locationDetails.mapLatitude"
              :locationLongitude="locationDetails.mapLongitude"
              :zoomLevel="locationDetails.mapZoomLevel"
              :pois="pois"
            />
          </div>
        </div>
      </div>
    </ValidationObserver>
    <div class="mb-3">
      <button type="button" @click="cancel" class="btn btn-cancel btn-lg">
        Cancel
      </button>
    </div>
  </div>
</template>

<script>
import ProfileService from "@/services/ProfileService.js";
import LocationService from "@/services/LocationService.js";
import SpotReservationService from "@/services/SpotReservationService.js";
import { ValidationObserver } from "vee-validate";
import FormErrorAlert from "@/components/alert/FormErrorAlert.vue";
import LocationInformation from "@/components/parkdetails/LocationInformation.vue";
import SpotSearch from "@/components/parkdetails/SpotSearch.vue";
import SpotMap from "@/components/parkdetails/SpotMap.vue";
import Alert from "@/components/alert/Alert.vue";

export default {
  name: "CustomerProfileReservationEdit",
  title: "Customer Profile Reservation Edit",
  props: {
    reservationId: String,
    customerId: String,
    locationId: String,
    externalUserId: String
  },
  components: {
    ValidationObserver,
    FormErrorAlert,
    LocationInformation,
    SpotSearch,
    SpotMap,
    Alert
  },
  data() {
    return {
      reservation: {},
      locationDetails: {},
      searchResults: [],
      productClassifications: [],
      pois: [],
      loaded: false,
      errors: []
    };
  },
  computed: {
    filteredClassifications() {
      const locationService = new LocationService(
        this.tenantId,
        this.locationId
      );
      const filteredClassifications = locationService.filterClassificationsFromSpotTypes(
        this.selectedSpotTypes,
        this.productClassifications
      );
      return filteredClassifications;
    },
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    },
    selectedSpotTypes() {
      return this.$store.getters["search/selectedSpotTypes"];
    },
    selectedProductClassifications() {
      return this.$store.getters["search/selectedProductClassifications"];
    },
    selectedAttributes() {
      return this.$store.getters["search/selectedAttributes"];
    },
    startDate: {
      get() {
        return this.$store.getters["search/startDate"];
      },
      set(val) {
        this.$store.commit("search/setSearchDate", {
          key: "startDate",
          value: val
        });
      }
    },
    endDate: {
      get() {
        return this.$store.getters["search/endDate"];
      },
      set(val) {
        this.$store.commit("search/setSearchDate", {
          key: "endDate",
          value: val
        });
      }
    },
    selectedSpotId() {
      return this.$store.getters["search/selectedSpotId"];
    },
    alert: {
      cache: false,
      get() {
        return this.$store.getters["alert/errorAlert"];
      }
    },
    lockCode() {
      const claimData = this.$store.getters["transaction/spotClaimInfo"];
      if (claimData == null) {
        return null;
      }
      return claimData.lockCode;
    },
    isFiltered() {
      return (
        this.selectedSpotTypes?.length > 0 ||
        this.selectedProductClassifications?.length > 0 ||
        this.selectedAttributes?.length > 0
      );
    }
  },
  watch: {
    selectedSpotTypes() {
      this.$store.commit("search/setSelectedProductClassifications", null);
      this.loadSpots();
    },
    selectedProductClassifications() {
      this.loadSpots();
    },
    selectedAttributes() {
      this.loadSpots();
    },
    startDate() {
      if (!this.loaded) return;
      this.loadSpots();
    },
    endDate() {
      if (!this.loaded) return;
      this.loadSpots();
    },
    selectedSpotId(newValue) {
      this.setSelectedSpotIdInSearchResults(newValue);
    }
  },
  methods: {
    onSubmit() {
      this.$refs.editResObserver.validate().then(success => {
        if (!success) {
          setTimeout(() => {
            const errors = Object.entries(this.$refs.editResObserver.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error["value"].length);
            this.errors = errors;
            this.$refs.editResObserver.refs[
              errors[0]["key"]
            ].$el.scrollIntoView({
              behavior: "smooth",
              block: "center"
            });
          }, 100);
        } else {
          this.errors = [];
        }
      });
    },
    getReservationDetail() {
      const profileService = new ProfileService(this.tenantId);
      this.$store.dispatch("search/clear");
      profileService
        .getReservationDetailForCustomer(+this.customerId, +this.reservationId)
        .then(response => {
          this.reservation = response.data;
          //Clear lock code since we don't want to use the old one here
          this.reservation.lockCode = null;
          this.reservation.discounts = this.reservation.discounts.map(
            x => x.discountClassId
          );
          this.$store.commit("transaction/setReservation", {
            oldStartDate: response.data.arrivalDate,
            oldEndDate: response.data.departureDate,
            oldSpotId: response.data.spotId,
            oldDiscounts: this.reservation.discounts,
            customerId: this.customerId,
            reservationId: this.reservationId,
            externalUserId: this.externalUserId,
            ...this.reservation
          });
          this.$store.commit("search/setSelectedSpotId", response.data.spotId);
          this.$store.commit("search/setSearchDate", {
            key: "startDate",
            value: response.data.arrivalDate
          });
          this.$store.commit("search/setSearchDate", {
            key: "endDate",
            value: response.data.departureDate
          });
          this.loaded = true;
        });
    },
    loadLocation() {
      const locationService = new LocationService(
        this.tenantId,
        this.locationId
      );
      locationService.getLocationDetails().then(response => {
        this.locationDetails = response;
      });
    },
    loadSpots() {
      const locationService = new LocationService(
        this.tenantId,
        this.locationId
      );
      const searchParams = {
        locationId: this.locationId,
        startDate: this.startDate,
        endDate: this.endDate,
        spotReservationId: this.reservationId,
        lockCode: this.lockCode,
        customerId: this.customerId,
        selectedSpotTypes: this.selectedSpotTypes,
        selectedProductClassifications: this.selectedProductClassifications,
        selectedAttributes: this.selectedAttributes,
        selectedSpotId: this.selectedSpotId
      };
      locationService.spotSearchForLocation(searchParams).then(response => {
        this.searchResults = response.spots ? response.spots : [];
        if (this.isFiltered) {
          this.searchResults.forEach(x => (x.isFilteredResult = true));
        }
        this.setSelectedSpotIdInSearchResults();
      });
    },
    loadPois() {
      const locationService = new LocationService(
        this.tenantId,
        this.locationId
      );
      locationService.getPointsOfInterestForLocation().then(response => {
        this.pois = response.locationPointsOfInterest;
      });
    },
    loadProductClassifications() {
      const locationService = new LocationService(
        this.tenantId,
        this.locationId
      );
      locationService.getClassificationsWithSpotTypes().then(response => {
        this.productClassifications =
          response.productClassificationsWithSpotTypes;
      });
    },
    setSelectedSpotIdInSearchResults() {
      const newValue = this.selectedSpotId;
      //Unset previously selectes spotId
      const result = this.searchResults.find(item => item.isSelected);
      if (result) {
        result.isSelected = false;
      }

      //Set new id
      const newSelected = this.searchResults.find(item => item.id == newValue);
      if (newSelected != null) {
        this.$set(newSelected, "isSelected", true);
      }
    },
    initialize() {
      this.getReservationDetail();
      this.loadLocation();
      this.loadSpots();
      this.loadPois();
      this.loadProductClassifications();
    },
    cancel() {
      //Release lock
      const spotReservationService = new SpotReservationService(this.tenantId);
      const claimData = this.$store.getters["transaction/spotClaimInfo"];
      //Ensure we have a lock
      if (claimData.lockCode) {
        spotReservationService
          .releaseSpotClaim(claimData.spotId, claimData.lockCode)
          .then(response => {
            if (response.statusCode === "Success") {
              this.clearReservationAndReturn();
            }
          });
      } else {
        this.clearReservationAndReturn();
      }
    },
    clearReservationAndReturn() {
      //Clear reservation changes
      const externalUserId = this.externalUserId;
      this.$store.dispatch("transaction/clearReservation");
      this.existingSpotClaimed = false;

      //Route to reservation list
      this.$router
        .push("/customer-profile/" + externalUserId + "#reservations")
        .catch(() => {});
    },
    checkErrorQueue() {
      const errors = this.$store.getters["alert/errorQueue"];
      errors.forEach(x => {
        this.$store.commit("alert/setErrorAlert", {
          type: "alert-danger",
          message: x,
          layer: "detail"
        });
      });
      this.$store.commit("alert/setErrorQueue", []);
    }
  },
  mounted() {
    this.$store.commit("search/setSearchView", "cal");
    this.initialize();
    this.checkErrorQueue();
  }
};
</script>

<style scoped>
.spotSearch {
  background-color: white;
}
.header-bar {
  height: 40px;
  background-color: #1078a8;
  color: #fff;
}
@media (min-width: 1000px) {
  .container-fluid {
    width: 90%;
  }
}
p {
  font-size: large;
}
</style>
